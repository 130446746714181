import { Routes } from '@angular/router';


export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./layouts/landing-layout.component'),
    loadChildren: () => [
      {
        path: '',
        loadComponent: () => import('./pages/home-page.component'),
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
